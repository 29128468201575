var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{class:_vm.$vuetify.theme.dark ? 'dark-top-bar' : 'white',attrs:{"id":"app-bar","app":"","dense":"","flat":""}},[(_vm.showBackButton)?_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.gotBack()}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-icon',[_vm._v(" mdi-apps ")])]},proxy:true}],null,false,1632923054)}):_vm._e(),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t(_vm.sectionTitle.title))+" ")]),_c('v-spacer'),_c('v-menu',{ref:"companiesNotificationMenu",attrs:{"bottom":"","offset-y":"","close-on-click":true,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{staticClass:"me-6",attrs:{"content":_vm.companiesNotification.count ? _vm.companiesNotification.count : 0,"value":_vm.companiesNotification.count ? _vm.companiesNotification.count : 0,"color":"error","overlap":"","offset-x":"0"}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-bell ")])],1)]}}])},[_c('v-card',{staticClass:"notification-card",attrs:{"width":"400","max-height":"650"}},[_c('v-list',{staticClass:"pt-2",attrs:{"subheader":"","dense":"","nav":""}},[_c('v-subheader',{staticClass:"text-subtitle-1 font-weight-bold ps-4"},[_vm._v(" "+_vm._s(_vm.$t('Lawyer Contract'))+" "),_c('v-chip',{staticClass:"ms-2 ",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.companiesNotification.lawyer_contract.length)+" ")])],1),_vm._l((_vm.companiesNotification.lawyer_contract),function(lawyerContract,index){return _c('v-list-item',{key:(index + "-" + (lawyerContract.id))},[_c('div',{staticClass:"d-flex"},[_c('v-list-item-avatar',{attrs:{"size":"38"}},[_c('img',{attrs:{"src":((_vm.$_file_path()) + "/media/" + (lawyerContract.company_logo) + "?token=" + (_vm.$_auth_token()))}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-2 font-weight-bold pb-1"},[_vm._v(" "+_vm._s(lawyerContract.company_name)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_c('div',{staticClass:"grey--text",class:{
                      'text--darken-2': !_vm.$vuetify.theme.dark,
                      'text--lighten-2': _vm.$vuetify.theme.dark,
                    }},[_c('div',[_vm._v(" "+_vm._s(lawyerContract.last_end_date)+" ")])])])],1)],1)])}),_c('v-divider',{staticClass:"my-3"}),_c('v-subheader',{staticClass:"text-subtitle-1 font-weight-bold ps-4"},[_vm._v(" "+_vm._s(_vm.$t('Social Security'))+" "),_c('v-chip',{staticClass:"ms-2 ",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.companiesNotification.social_security.length)+" ")])],1),_vm._l((_vm.companiesNotification.social_security),function(socialSecurity,index){return _c('v-list-item',{key:(index + "-" + (socialSecurity.id))},[_c('div',{staticClass:"d-flex"},[_c('v-list-item-avatar',{attrs:{"size":"38"}},[_c('img',{attrs:{"src":((_vm.$_file_path()) + "/media/" + (socialSecurity.company_logo) + "?token=" + (_vm.$_auth_token()))}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-2 font-weight-bold pb-1"},[_vm._v(" "+_vm._s(socialSecurity.full_name)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_c('div',{staticClass:"grey--text",class:{
                      'text--darken-2': !_vm.$vuetify.theme.dark,
                      'text--lighten-2': _vm.$vuetify.theme.dark,
                    }},[_c('div',[_vm._v(" "+_vm._s(socialSecurity.company_name)+" "),_c('span',{staticClass:"ms-4"},[_vm._v(" "+_vm._s(_vm.$_number_format(socialSecurity.salary))+" ")])])])])],1)],1)])}),_c('v-divider',{staticClass:"my-3"}),_c('v-subheader',{staticClass:"text-subtitle-1 font-weight-bold ps-4"},[_vm._v(" "+_vm._s(_vm.$t('Seasonal & Yearly Form'))+" "),_c('v-chip',{staticClass:"ms-2 ",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.companiesNotification.seasonal_form.length)+" ")])],1),_vm._l((_vm.companiesNotification.seasonal_form),function(seasonalForm,index){return _c('v-list-item',{key:(index + "-" + (seasonalForm.id))},[_c('div',{staticClass:"d-flex"},[_c('v-list-item-avatar',{attrs:{"size":"38"}},[_c('img',{attrs:{"src":((_vm.$_file_path()) + "/media/" + (seasonalForm.company_logo) + "?token=" + (_vm.$_auth_token()))}})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-body-2 font-weight-bold pb-1"},[_vm._v(" "+_vm._s(seasonalForm.company_name)+" ")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_c('div',{staticClass:"grey--text",class:{
                      'text--darken-2': !_vm.$vuetify.theme.dark,
                      'text--lighten-2': _vm.$vuetify.theme.dark,
                    }},[_c('div',[_vm._v(" "+_vm._s(seasonalForm.end_date)+" ")])])])],1)],1)])})],2)],1)],1),_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.authUserData.username)+" "),_c('v-icon',[_vm._v("mdil-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.goToProfile()}}},[_c('v-list-item-icon',{staticClass:"ms-1 me-3"},[_c('v-icon',[_vm._v("mdi-account-outline")])],1),_c('v-list-item-content',{staticClass:"me-3"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Profile'))+" ")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.setLtr()}}},[_c('v-list-item-icon',{staticClass:"ms-1 me-3"},[_c('v-icon',[_vm._v("mdi-translate")])],1),_c('v-list-item-content',{staticClass:"me-3"},[_c('v-list-item-title',[_vm._v(" English ")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.setRtl()}}},[_c('v-list-item-icon',{staticClass:"ms-1 me-3"},[_c('v-icon',[_vm._v("mdi-translate")])],1),_c('v-list-item-content',{staticClass:"me-3"},[_c('v-list-item-title',[_vm._v(" کوردی ")])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.changeTheme()}}},[_c('v-list-item-icon',{staticClass:"ms-1 me-3"},[_c('v-icon',[_vm._v("mdi-theme-light-dark")])],1),_c('v-list-item-content',{staticClass:"me-3"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('Dark mode'))+" ")])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.logoutUser()}}},[_c('v-list-item-icon',{staticClass:"ms-1 me-3"},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-logout ")])],1),_c('v-list-item-content',{staticClass:"me-3"},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t('Logout'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }